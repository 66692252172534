import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api';

/**
 * Initializes Vue Application and their libraries
 */

// Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    closeButton: false
};
Vue.use(Toast, options);

// Pinia
const pinia = createPinia()
Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionAPI);

// Config
Vue.config.productionTip = false
Vue.config.silent = true

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

// Init
new Vue({
  router,
  vuetify,
  pinia,
  render: function (h) { return h(App) },
  mounted() {
    window.globalToast = this.$toast;
  }
}).$mount('#app')